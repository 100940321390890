import { Box, Button, IconButton, Menu, MenuItem } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Dehaze } from '@material-ui/icons'
import * as React from 'react'
import { connect, useSelector } from 'react-redux'
import { useSessionStorage } from 'react-use'
import styled from 'styled-components'
import { useIsMobile } from '../../components/useIsMobile'
import { getLoginUser } from '../../state/Auth/selectors'
import { loadDevices, syncUserData } from '../../state/Firebase/operations'
import { getUserYuatsuConfig } from '../../state/UserById/selectors'
import { State } from '../../types'
import NavBar from '../NavBarContainer'
import TimestampLabel from '../System/TimestampLabel'
import AlertConfigModal from './AlertConfigModal'
import DevicePanel from './DevicePanel'
import Ruler from './Ruler'
import ViewConfigModal from './ViewConfigModal'

type Props = {
  title: string
  loadDevices: () => void
  syncUserData: () => void
}

function ConcreteMonitorAppPage(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget)
  const closeMenu = () => setAnchorEl(null)

  React.useEffect(() => {
    props.loadDevices()
    props.syncUserData()
    // eslint-disable-next-line
  }, [])
  const isMobile = useIsMobile()
  const user = useSelector(getLoginUser)

  const [alertCnfOpen, setAlertCnfOpen] = useSessionStorage<boolean>(
    'alert_modal_open',
    false,
  )
  const [isModalOpen, setIsModalOpen] = useSessionStorage<boolean>(
    'modal_open',
    false,
  )
  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)
  const mailNotifyEnabled =
    user.allowMailNotify &&
    Object.values(user.yuatsu?.alertConfs || {}).some(
      v => v.mm.enabled || v.mpa.enabled,
    )

  return (
    <Style data-mobile={isMobile} data-allow-mail={user.allowMailNotify}>
      <NavBar />
      <Grid container justify={'center'}>
        <Grid item xs={12} sm={12} md={12}>
          <div className="head">
            <div style={{ gridArea: 'title' }}>
              <Typography variant={'h5'}>{props.title}</Typography>
              <TimestampLabel />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gridArea: 'btns',
                gap: '8px',
              }}
            >
              <Box mt={3} mr={1}>
                {user.allowMailNotify && (
                  <Typography>
                    アラート通知:
                    <span
                      style={{
                        color: mailNotifyEnabled ? 'green' : 'red',
                      }}
                    >
                      {mailNotifyEnabled ? '有効' : '無効'}
                    </span>
                  </Typography>
                )}
              </Box>
              <Button
                onClick={() => setAlertCnfOpen(true)}
                className={'alert-config-btn'}
                data-pc-view
              >
                アラート設定
              </Button>
              <Button onClick={openModal} data-pc-view>
                表示設定
              </Button>
              {isMobile && (
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={openMenu}
                >
                  <Dehaze fontSize="large" />
                </IconButton>
              )}
            </div>
          </div>
          <div style={{ display: 'grid', justifyContent: 'end' }}>
            <div style={{ width: isMobile ? '100vw' : '50vw' }}>
              <Ruler />
            </div>
          </div>
          <DevicePanel />
        </Grid>
      </Grid>
      <ViewConfigModal
        modalOpen={isModalOpen}
        handleConcreteModalClose={closeModal}
      />
      <AlertConfigModal
        modalOpen={alertCnfOpen}
        handleConcreteModalClose={() => setAlertCnfOpen(false)}
      />
      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <MenuItem
          onClick={() => {
            openModal()
            closeMenu()
          }}
        >
          表示設定
        </MenuItem>

        {user.allowMailNotify && (
          <MenuItem
            onClick={() => {
              setAlertCnfOpen(true)
              closeMenu()
            }}
          >
            アラート設定
          </MenuItem>
        )}
      </Menu>
    </Style>
  )
}

type OProps = {}

const conn = connect(
  (state: State, op: OProps) => ({
    title: getUserYuatsuConfig(state).title,
  }),
  {
    loadDevices,
    syncUserData,
  },
)

const Style = styled.div`
  .head {
    padding: 12px;
    display: grid;
    grid-template-areas: 'title btns';
    width: 100%;
    justify-content: space-between;
  }
  .alert-config-btn {
    display: none;
    line-height: 1.2rem;
    width: 90px;
  }

  &[data-allow-mail='true'] {
    .alert-config-btn {
      display: block;
    }
  }
  &[data-mobile='true'] {
    [data-pc-view='true'] {
      display: none;
    }
    .head {
      display: grid;
      grid-template-areas: 'title' 'btns';
      grid-template-columns: 1fr;
    }
  }
`

export default conn(ConcreteMonitorAppPage)
