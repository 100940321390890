import { Action, AnyAction } from 'redux'
import { ThunkAction as _ThunkAction } from 'redux-thunk'
import { State as _State } from './state'

export type State = _State

export type GetState = () => State

export type ThunkAction = _ThunkAction<
  void | Promise<void>,
  State,
  undefined,
  AnyAction | Action<any>
>
export type YuatsuConfig = {
  title: string
  row: number
  col: number
  colorMode: string
  mmUnit: string
  minMpa: number
  maxMpa: number
  pointMt: number
  pointMm: number
  memoLeft: string
  memoRight: string
  cells: { [pos: string]: string }
  pressureArea: number
  pressureAreaMode: 'pa-batch' | 'pa-individual'
  pressureAreaIndividual: { [id: string]: number }
  alertConfs: Record<string, AlertConfig>
}
export type AlertConfig = {
  mpa: { dw: number | null; up: number | null; enabled: boolean }
  mm: { dw: number | null; up: number | null; enabled: boolean }
}
export const initAlertConfig: AlertConfig = {
  mpa: { dw: null, up: null, enabled: false },
  mm: { dw: null, up: null, enabled: false },
}

export type UpdateCellsField = Pick<
  YuatsuConfig,
  'cells' | 'memoLeft' | 'memoRight'
>
export type UpdateRowColField = Pick<YuatsuConfig, 'row' | 'col'>
export type UpdateAlertField = Pick<YuatsuConfig, 'alertConfs'>

export type User = {
  id: string
  email: string
  memo: string
  label: string
  deleted: boolean
  allowIntegration: boolean
  allowDownload: boolean
  allowDialGauge: boolean
  allowMailNotify: boolean
  tokenBase64: string
  deviceIds: { [id: string]: true }
  title: string
  yuatsu?: YuatsuConfig
}

export type Admin = {
  id: string
  label: string
  email: string
}

export type Auth =
  | {
      loading: true
      authorized: false
    }
  | {
      loading: false
      authorized: false
    }
  | {
      loading: false
      authorized: true
      isAdmin: boolean
      userId: string
    }

export type LoginField = {
  email: string
  password: string
}

export type FieldError =
  | {
      hasError: true
      message: string
    }
  | {
      hasError: false
    }

export type Log = {
  id: string
  timestamp: number
  timeLabel: string
  fields: Fields
}

export type LogRaw = {
  fields: Fields
  timestamp: number
}

export type DayLogs = {
  id: string
  logs: Log[]
}

export type DayLogCount = {
  timeLabel: string
  count: number
}

export type LogCount = {
  id: string
  days: DayLogCount[]
}

export type SessionRaw = {
  createdAt: number
}
export type Session = {
  id: string
  createdAt: number
}

export type Device = {
  id: string
  selector: string
  currentUserId: string
  currentSessionId: number
  data: string
  info: {
    label: string
  }
  updatedAt?: number // TODO
  lastLogs: { [session: number]: Log }
}

export type DeviceRaw = {
  category: string
  currentSessionId: number
  currentUserId: string
  data: string
  info: any
  updatedAt: number
}

export type System = {}

export type Clock = {
  timestamp: number
  timestampStr: string
}

export type SystemCache = {
  deviceLoading: boolean
  toggles: { [key: string]: boolean }
  showName: boolean
}

export type Fields = {
  mpa: number
  temp?: number
  volt?: number
  seq?: number
  len?: number
}

export type GraphData = [Date, number, number][]
