import _ from 'lodash'
import { State, User, YuatsuConfig } from '../../types'
import { getLoginUser } from '../Auth/selectors'

export const getUserAll = (state: State) => {
  return _.values(state.UserById)
}

export const getUserIds = (state: State) => {
  return _.keys(state.UserById)
}

export const getUser = (state: State, id: string): User => {
  return state.UserById[id]
}

export const initialConfig: YuatsuConfig = {
  title: '現場',
  row: 3,
  col: 8,
  minMpa: 2,
  maxMpa: 6,
  memoLeft: '自由記入欄',
  memoRight: '自由記入欄',
  pointMm: 1,
  pointMt: 1,
  colorMode: 'mpa',
  cells: {},
  alertConfs: {},
  pressureArea: 10000, // 10000(cm^2), 1(m^2)
  pressureAreaMode: 'pa-batch',
  pressureAreaIndividual: {},
  mmUnit: 'mpa',
}

const mmPatch = (config: YuatsuConfig, user: User): YuatsuConfig => {
  if (user.allowDialGauge) return config
  if (config.colorMode !== 'mm') return config
  return {
    ...config,
    colorMode: 'mpa',
  }
}

export const getUserYuatsuConfig = (state: State): YuatsuConfig => {
  const user = getLoginUser(state)

  if (!user) return initialConfig

  return mmPatch(Object.assign({}, initialConfig, user.yuatsu), user)
}
