import { Typography } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import gconfig from '../../config'
import { getRuler } from '../../state/DeviceById/selectors'
import { getUserYuatsuConfig } from '../../state/UserById/selectors'
import { round, toUnit } from '../../utils'

const size = 8

function Ruler() {
  const ruler = useSelector(getRuler)
  const config = useSelector(getUserYuatsuConfig)

  if (!ruler) return null
  const w = `${Math.floor((100 / (size - 1)) * 1000) / 1000}%`

  const unitStr = toUnit(config.colorMode)

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1 }}>
        {
          <BoxWrap
            style={{
              gridTemplateColumns: `repeat(${size}, ${w})`,
            }}
          >
            <Box key={-1}>
              <Typography variant="h6" />
            </Box>
            {ruler.map((v, i) => (
              <Box key={i}>
                <Typography>{round(v)}</Typography>
              </Box>
            ))}
          </BoxWrap>
        }
        <BoxWrap
          style={{
            border: 'solid black 1px',
            height: '2rem',
            marginLeft: `calc(${w} / 2)`,
            background: 'black',
          }}
        >
          {gconfig.colors.map((col, i) => (
            <Box key={i} style={{ background: col }}></Box>
          ))}
        </BoxWrap>
      </div>
      <div style={{ margin: '0 12px 0 0' }}>
        <Typography style={{ margin: 0, width: '4rem', textAlign: 'center' }}>
          [{unitStr}]
        </Typography>
      </div>
    </div>
  )
}

const BoxWrap = styled.div`
  width: 100%;
  /* justify-content: start; */
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1px;
  grid-template-columns: repeat(${size - 1}, 1fr);
`
const Box = styled.div`
  text-align: center;
`

export default Ruler
