import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import { FormikErrors, useFormik } from 'formik'
import React from 'react'
import { useSelector } from 'react-redux'
import { getLoginUser } from '../../../state/Auth/selectors'
import { updateYuatsuField } from '../../../state/Firebase/operations'
import { getUserYuatsuConfig } from '../../../state/UserById/selectors'

type Props = {}

type Fields = {
  maxMpa: number
  minMpa: number
  colorMode: string
  mmUnit: string
}

function ScaleForm(props: Props) {
  const user = useSelector(getLoginUser)
  const config = useSelector(getUserYuatsuConfig)

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldTouched,
    isSubmitting,
  } = useFormik<Fields>({
    initialValues: {
      minMpa: config.minMpa,
      maxMpa: config.maxMpa,
      colorMode: config.colorMode,
      mmUnit: config.mmUnit,
    },
    validate: values => {
      const errors: FormikErrors<Fields> = {}

      if (typeof values.minMpa !== 'number') {
        // errors.minMpa = '0以上の範囲です'
        errors.minMpa = '数値でありません'
      }
      if (typeof values.maxMpa !== 'number') {
        errors.maxMpa = '数値でありません'
        // errors.maxMpa = '0以上の範囲です'
      } else if (values.maxMpa <= values.minMpa) {
        errors.maxMpa = '下閾値より大きい必要があります'
      }
      return errors
    },
    onSubmit: (values: Fields, { setSubmitting, setErrors }) => {
      updateYuatsuField(user.id, values).then(() => {
        setSubmitting(false)
      })
    },
  })

  const change = (title, e) => {
    e.persist()
    handleChange(e)
    setFieldTouched(title, true, false)
  }

  const disabled =
    isSubmitting ||
    (values.minMpa === config.minMpa &&
      values.maxMpa === config.maxMpa &&
      values.colorMode === config.colorMode &&
      values.mmUnit === config.mmUnit)

  const options = user.allowDialGauge
    ? [
        { key: 'mpa', label: '液圧(MPa)' },
        { key: 't', label: '荷重(t)' },
        { key: 'mm', label: '変位(mm)' },
      ]
    : [
        { key: 'mpa', label: '液圧(MPa)' },
        { key: 't', label: '荷重(t)' },
      ]

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr max-content',
        }}
      >
        <div>
          <div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">色分け表示設定</FormLabel>

                <RadioGroup
                  aria-label="color-mode"
                  value={values.colorMode}
                  onChange={change.bind(null, 'colorMode')}
                  name="colorMode"
                  style={{ display: 'flex' }}
                >
                  {options.map(option => (
                    <FormControlLabel
                      key={option.key}
                      value={option.key}
                      control={<Radio />}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <FormControl
                component="fieldset"
                style={{
                  display: values.colorMode === 'mm' ? 'block' : 'none',
                }}
              >
                <FormLabel component="legend">単位表示設定</FormLabel>

                <RadioGroup
                  aria-label="mm-unit"
                  defaultValue={values.mmUnit}
                  onChange={change.bind(null, 'mmUnit')}
                  name="mmUnit"
                  style={{ display: 'flex' }}
                >
                  <FormControlLabel
                    value="mpa"
                    control={<Radio />}
                    label="液圧(MPa)"
                  />
                  <FormControlLabel
                    value="t"
                    control={<Radio />}
                    label="荷重(t)"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>

          <div style={{ display: 'flex' }}>
            <TextField
              id="minMpa"
              name="minMpa"
              type="number"
              helperText={touched.minMpa ? errors.minMpa : ''}
              error={touched.minMpa && Boolean(errors.minMpa)}
              label="下閾値"
              // InputProps={{ inputProps: { min: -20 } }}
              value={values.minMpa}
              onChange={change.bind(null, 'minMpa')}
              fullWidth
            />
            <TextField
              id="maxMpa"
              name="maxMpa"
              type="number"
              helperText={touched.maxMpa ? errors.maxMpa : ''}
              error={touched.maxMpa && Boolean(errors.maxMpa)}
              label="上閾値"
              // InputProps={{ inputProps: { min: 0 } }}
              value={values.maxMpa}
              onChange={change.bind(null, 'maxMpa')}
              fullWidth
            />
          </div>
        </div>
        <Button
          color="primary"
          disabled={disabled}
          onClick={e => handleSubmit()}
        >
          変更する
        </Button>
      </div>
    </form>
  )
}

export default ScaleForm
