import { YuatsuConfig } from '../types'

export const adjustPa = (config: YuatsuConfig, deviceId: string) => {
  return config.pressureAreaMode === 'pa-batch'
    ? config.pressureArea
    : config.pressureAreaIndividual[deviceId] ?? config.pressureArea
}

// 個別設定の場合のルーラーを全体値の値に scale する
export const adjustMpa = (
  mpa: number,
  config: YuatsuConfig,
  deviceId: string,
) => {
  if (config.colorMode !== 't' || config.pressureAreaMode === 'pa-batch')
    return mpa
  return mpa * (adjustPa(config, deviceId) / config.pressureArea)
}
