import React from 'react'
import {
  Button,
  FormControl,
  FormControlLabel,
  Input,
  InputAdornment,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { FormikErrors, useFormik } from 'formik'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { getLoginUser } from '../../../state/Auth/selectors'
import { getDeviceIds } from '../../../state/DeviceById/selectors'
import { updateYuatsuField } from '../../../state/Firebase/operations'
import { getUserYuatsuConfig } from '../../../state/UserById/selectors'
import { YuatsuConfig } from '../../../types'

type Fields = Pick<
  YuatsuConfig,
  'pressureArea' | 'pressureAreaMode' | 'pressureAreaIndividual'
>

type Props = {}

function PressureAreaForm(props: Props) {
  const user = useSelector(getLoginUser)
  const config = useSelector(getUserYuatsuConfig)
  const deviceIds = useSelector(getDeviceIds)

  const currentPressureAreaIndividual = deviceIds.reduce(
    (p, c) => ({
      ...p,
      [c]: config.pressureAreaIndividual[c] ?? config.pressureArea,
    }),
    {},
  )

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
  } = useFormik<Fields>({
    initialValues: {
      pressureArea: config.pressureArea,
      pressureAreaIndividual: currentPressureAreaIndividual,
      pressureAreaMode: config.pressureAreaMode,
    },
    enableReinitialize: true,
    validate: values => {
      const errors: FormikErrors<Fields> = {}

      if (!values.pressureArea) {
        errors.pressureArea = 'Required'
      }
      if (values.pressureAreaMode !== 'pa-batch') {
      } else {
        deviceIds.forEach(deviceId => {
          if (!values.pressureAreaIndividual[deviceId]) {
            errors.pressureAreaIndividual = {
              ...errors.pressureAreaIndividual,
              [deviceId]: 'Required',
            }
          }
        })
      }

      return errors
    },
    onSubmit: (values: Fields, { setSubmitting, setErrors }) => {
      updateYuatsuField(user.id, values).then(() => {
        setSubmitting(false)
      })
    },
  })

  const change = (pressureArea, e) => {
    e.persist()
    handleChange(e)
    setFieldTouched(pressureArea, true, false)
  }

  const changed =
    values.pressureAreaMode !== config.pressureAreaMode ||
    values.pressureArea !== config.pressureArea ||
    (values.pressureAreaMode === 'pa-individual' &&
      deviceIds.some(
        did =>
          values.pressureAreaIndividual[did] !==
          currentPressureAreaIndividual[did],
      ))

  const disabled = isSubmitting || !!errors.pressureArea || !changed

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr max-content',
        }}
      >
        <FormControl>
          <Typography component="legend">受圧面積</Typography>
          <div>
            <div>
              <RadioGroup
                name="pressureAreaMode"
                aria-label="batch-pressure-area"
                defaultValue={values.pressureAreaMode}
                onChange={change.bind(null, 'pressureAreaMode')}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <FormControlLabel
                  value="pa-batch"
                  control={<Radio />}
                  label="一括"
                />
                <FormControlLabel
                  value="pa-individual"
                  control={<Radio />}
                  label="個別"
                />
              </RadioGroup>
            </div>
          </div>

          {values.pressureAreaMode === 'pa-batch' ? (
            <div>
              <Input
                id="pressureArea"
                name="pressureArea"
                type="number"
                inputProps={{ min: 1 }}
                style={{ width: '30%' }}
                error={touched.pressureArea && Boolean(errors.pressureArea)}
                endAdornment={
                  <InputAdornment position="end">
                    cm<sup>2</sup>
                  </InputAdornment>
                }
                value={values.pressureArea}
                onChange={change.bind(null, 'pressureArea')}
                fullWidth
              />
            </div>
          ) : (
            <div>
              <div>
                <Input
                  id="pressureArea"
                  name="pressureArea"
                  type="number"
                  disabled={values.pressureAreaMode === 'pa-individual'}
                  inputProps={{ min: 1 }}
                  style={{
                    width: '30%',
                    background:
                      values.pressureAreaMode === 'pa-individual'
                        ? '#eee'
                        : 'white',
                  }}
                  error={touched.pressureArea && Boolean(errors.pressureArea)}
                  endAdornment={
                    <InputAdornment position="end">
                      cm<sup>2</sup>
                    </InputAdornment>
                  }
                  value={values.pressureArea}
                  onChange={change.bind(null, 'pressureArea')}
                  fullWidth
                />
              </div>
              {deviceIds.map(deviceId => {
                const v =
                  values.pressureAreaIndividual[deviceId] ?? config.pressureArea

                return (
                  <FieldGrid key={`pa-device-${deviceId}`}>
                    <Typography component="legend">{deviceId}</Typography>
                    <Input
                      type="number"
                      inputProps={{ min: 1 }}
                      error={
                        touched.pressureAreaIndividual?.[deviceId] &&
                        Boolean(errors.pressureAreaIndividual?.[deviceId])
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          cm<sup>2</sup>
                        </InputAdornment>
                      }
                      value={v}
                      onChange={e => {
                        setFieldValue('pressureAreaIndividual', {
                          ...values.pressureAreaIndividual,
                          [deviceId]: Number(e.currentTarget.value),
                        })
                        setFieldTouched(
                          `pressureAreaIndividual.${deviceId}`,
                          true,
                          false,
                        )
                      }}
                      fullWidth
                    />
                  </FieldGrid>
                )
              })}
            </div>
          )}
        </FormControl>
        <Button
          color="primary"
          disabled={disabled}
          onClick={e => handleSubmit()}
        >
          変更する
        </Button>
      </div>
    </form>
  )
}

const FieldGrid = styled.div`
  display: grid;
  grid-template-columns: 40% 30%;
  gap: 8px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr; /* スマホサイズで縦に並べる */
  }
`

export default PressureAreaForm
